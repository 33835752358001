import React, { useState, Suspense, useEffect, lazy, useRef } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Swal from 'sweetalert2';
import io from 'socket.io-client';

import './sass_components/App.sass';

// Helpers
import history from './react_components/helpers/history.jsx';
import { useStateWithLocalStorage } from './react_components/helpers/storage.jsx';
import { GlobalContext } from './react_components/helpers/globalContext.jsx';
import {
  cookies,
  setCookieToken,
} from './react_components/helpers/cookies.jsx';
import { ModalProvider } from './react_components/helpers/modal_provider.jsx';
import * as API from './react_components/helpers/api.jsx';
import config from './config';
// eslint-disable-next-line
// import Cursor from "./react_components/helpers/cursor";
import Loading from './react_components/helpers/loading.jsx';

// import Footer from './react_components/reusable-components/footer';
import Header from './react_components/reusable-components/header/header.jsx';
import { LazyLoadComponent } from './react_components/helpers/lazyLoadComponents.jsx';
// eslint-disable-next-line no-use-before-define

const Footer = lazy(() =>
  import('./react_components/reusable-components/footer.jsx')
);

const ModalWrapper = lazy(() =>
  import('./react_components/helpers/modal_wrapper.jsx')
);

const AboutUs = React.lazy(() =>
  import('./react_components/pages/aboutus.jsx')
);

const Contact = React.lazy(() =>
  import('./react_components/pages/contact.jsx')
);

const Login = React.lazy(() => import('./react_components/pages/login.jsx'));

const Account = React.lazy(() =>
  import('./react_components/pages/account.jsx')
);

const Corporate = React.lazy(() =>
  import('./react_components/pages/corporate.jsx')
);

const Page404 = React.lazy(() =>
  import('./react_components/pages/page404.jsx')
);

const Stream = React.lazy(() => import('./react_components/pages/stream.jsx'));

const PrivacyPolicy = React.lazy(() =>
  import('./react_components/pages/privacyPolicy.jsx')
);

const TermsOfService = React.lazy(() =>
  import('./react_components/pages/termsOfService.jsx')
);

let fft;
let amp;
let AudioElement;
let audioSource;
let audioCtx;
let analyser;
let source;
let ctx;
let particles = [];
let splitter;
let analyserR;
let analyserL;
let bufferLengthL;
let bufferLengthR;
let audioDataArrayR;
let audioDataArrayL;
let currentTime;
let StreamInterval;

function RouterComponent() {
  return (
    <Switch>
      <Route exact path='/' component={Stream} />
      <Route exact path='/about-us' component={AboutUs} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/account' component={Account} />
      <Route exact path='/Stream' component={Stream} />
      <Route exact path='/corporate' component={Corporate} />
      <Route exact path={config.privacyPolicy.link} component={PrivacyPolicy} />
      <Route
        exact
        path={config.termsOfService.link}
        component={TermsOfService}
      />
      <Route path='*' component={Page404} status={404} />
    </Switch>
  );
}

function App() {
  const [lang, setLang] = useStateWithLocalStorage('language');
  const [theme, setTheme] = useStateWithLocalStorage('theme');
  const [loggedIn, setLoggedIn] = useState(Boolean);
  const [keyFile, setKeyFile] = useState(Boolean);
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [modalFor, setModalFor] = useState('');
  const url = new URL(window.location.href);
  const Canvas = useRef(null);
  const restParams = {
    lang: url.searchParams.get('lang'),
  };
  const [adult, setAdult] = useState(undefined);

  //! Stream specific variables
  const [introText, setIntroText] = useState('Start exploring');
  const [play, setPlay] = useState();
  const [canPlay, setCanPlay] = useState(true);
  const [likeFade, setLikeFade] = useState();
  const [audioVolume, setAudioVolume] = useState(1);
  const [likes, setLikes] = useState(0);
  const [listening, setListening] = useState(0);
  const [trackName, setTrackName] = useState();
  const [streamID, setStreamID] = useState();
  const [streams, setStreams] = useState(false);
  const [streamName, setStreamName] = useState();
  const centerSectionWrapper = useRef(null);
  const centerSectionSpeaker = useRef(null);
  const [numberCurrent, setNumberCurrent] = useState();
  const [numberDuration, setNumberDuration] = useState();

  //! p5 specific variables
  const [oneTimestate, setOneTimestate] = useState(true);
  let duration;
  let minutes;
  let seconds;
  let bufferLength;
  let dataArray;
  let barWidth;
  let barHeight;
  let x = 0;

  async function logout() {
    setCookieToken('');
    setLoggedIn(false);
    setUser({});
  }
  function login(username, password) {
    if (username && password) {
      API.post(config.authentication.loginToken, {
        username,
        password,
      })
        .then((res) => {
          API.settings.headers.token = res.token;
          if (res.client) {
            setUser(res.client);
          }
          setCookieToken(res.token);
          setLoggedIn(true);
        })
        .catch((error) => {
          Swal.fire(config.swal.thereIsNoUser[lang]);
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      Swal.fire.error('Մուտքագրված տվյալների սխալմունք');
      console.log(
        '%c There is no such a user! ',
        'background: #222; color: #04b4e0'
      );
    }
  }
  function checkToken() {
    const cookieToken = cookies.get(config.authentication.cookieToken);
    if (!!cookieToken && cookieToken !== 'undefined') {
      API.post(config.authentication.verifyToken, {
        token: cookieToken,
      })
        .then((res) => {
          if (res) {
            if (res.status === 'success' || res.status === 200) {
              if (res.client) {
                setUser(res.client);
              }
              setLoggedIn(true);
            }
            // setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(`Please don't edit cookies manually`);
          cookies.remove('authorization');
        });
    } else {
      API.post(config.authentication.verifyKeyFile, {
        keyFile: config.authentication.key,
      })
        .then((res) => {
          if (res.status === 'success' || res.status === 200) {
            setKeyFile(true);
          }
        })
        .catch((res) => {
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          cookies.remove('authorization');
          setKeyFile(false);
        });
    }
  }
  // eslint-disable-next-line
  const openModal = (data, state, unused, url) => {
    document.body.classList.add('show-modal');
    data?.href &&
      window.history.pushState(data?.href, '', `/product/${data?.href}`);
    setModalFor(data);
    setOpen(true);
  };
  const closeModal = (goBack) => {
    document.body.classList.remove('show-modal');
    goBack && window.history.go(-1);
    setOpen(false);
  };
  const modalToggle = () => {
    if (!open) document.body.classList.add('show-modal');
    else document.body.classList.remove('show-modal');
    setOpen(!open);
  };
  //! Stream specific functions
  const PlayStream = () => {
    setPlay(true);
  };
  const Like = () => {
    setLikeFade(true);
    setTimeout(() => {
      setLikeFade(false);
    }, 500);
    const socket = io(`${config.api.API_URL}`, { transports: ['websocket'] });
    socket.emit('add-like', { socket_id: socket.id });
  };
  const Mute = () => {
    if (AudioElement) {
      if (audioVolume === 1) {
        setAudioVolume(0);
      } else {
        setAudioVolume(1);
      }
    } else {
      console.log('song is not defined');
    }
  };
  const GetAudio = async (streamID) => {
    if (streamID) {
      try {
        const audioFile = await API.get('free-play', { _id: streamID });
        console.log('getting audio try');
        if (audioFile) {
          console.log('🚀 - App - audioFile:', audioFile);
          console.log('getting audio try if audioFile');
          setTrackName(audioFile.name);
          return {
            trackSource: `${config.api.API_URL}/${config.api.API_VERSION}/play/${audioFile?.upload?._id}`,
            currentTime: audioFile.currentTime,
          };
        } else {
          console.log('Audio file not found');
          return false;
        }
      } catch (error) {
        console.log('getting audio catch');
        if (oneTimestate) {
          console.log(error);
          console.log('Audio file not found, but we will try again');
          setOneTimestate(true);
          try {
            const audioFile = await API.get('free-play');
            if (audioFile) {
              setTrackName(audioFile.name);
              return {
                trackSource: `${config.api.API_URL}/${config.api.API_VERSION}/play/${audioFile?.upload?._id}`,
                currentTime: audioFile.currentTime,
              };
            } else {
              console.log('Audio file not found');
              return false;
            }
          } catch (error) {
            console.log(error);
            return false;
          }
        } else {
          console.log('getting audio catch error');
          console.log(error);
          return false;
        }
      }
    } else {
      try {
        const audioFile = await API.get('free-play');
        console.log('getting audio try');
        if (audioFile) {
          console.log('🚀 - App - audioFile:', audioFile);

          console.log('getting audio try if audioFile');
          setTrackName(audioFile.name);
          return {
            trackSource: `${config.api.API_URL}/${config.api.API_VERSION}/play/${audioFile?.upload?._id}`,
            currentTime: audioFile.currentTime,
          };
        } else {
          console.log('Audio file not found');
          return false;
        }
      } catch (error) {
        console.log('getting audio catch');
        if (oneTimestate) {
          console.log(error);
          console.log('Audio file not found, but we will try again');
          setOneTimestate(true);
          try {
            const audioFile = await API.get('free-play');
            if (audioFile) {
              setTrackName(audioFile.name);
              return {
                trackSource: `${config.api.API_URL}/${config.api.API_VERSION}/play/${audioFile?.upload?._id}`,
                currentTime: audioFile.currentTime,
              };
            } else {
              console.log('Audio file not found');
              return false;
            }
          } catch (error) {
            console.log(error);
            return false;
          }
        } else {
          console.log('getting audio catch error');
          console.log(error);
          return false;
        }
      }
    }
  };
  const LoadAudio = async (streamID) => {
    const { trackSource, currentTime } = await GetAudio(streamID);

    minutes = 0;
    seconds = 0;

    AudioElement.loop = false;
    // AudioElement.autoplay = true;
    AudioElement.crossOrigin = 'anonymous';

    if (trackSource) {
      AudioElement.src = trackSource;
      AudioElement.load();
      AudioElement.currentTime = currentTime;
      AudioElement.oncanplaythrough = () => {
        AudioElement.play();
        AudioElement.volume = audioVolume;
      };
    } else {
      AudioElement.currentTime = 0;
      AudioElement.play();
    }
  };
  const animate = () => {
    x = 0;
    // analyser.getByteFrequencyData(dataArray);
    // barWidth = (Canvas.current.width + 200) / bufferLength;

    let centerX = Canvas.current.width / 2;
    let centerY = Canvas.current.height / 2;
    let radius = document.body.clientWidth <= 425 ? 120 : 160;
    let steps = document.body.clientWidth <= 425 ? 60 : 120;
    let interval = 100 / steps;
    let pointsUp = [];
    let pointsUp1 = [];
    let pointsUp2 = [];
    let pointsDown = [];
    let running = false;
    let pCircle = 2 * Math.PI * radius;
    let angleExtra = 0;

    // Create points
    for (let angle = 0; angle < 360; angle += interval) {
      let distUp = 1;
      let distDown = 0.9;

      pointsUp.push({
        angle: angle + angleExtra,
        x:
          centerX +
          radius * Math.cos(((-angle + angleExtra) * Math.PI) / 180) * distUp,
        y:
          centerY +
          radius * Math.sin(((-angle + angleExtra) * Math.PI) / 180) * distUp,
        dist: distUp,
      });

      pointsUp1.push({
        angle: angle + angleExtra,
        x:
          centerX +
          radius * Math.cos(((-angle + angleExtra) * Math.PI) / 180) * distUp +
          8,
        y:
          centerY +
          radius * Math.sin(((-angle + angleExtra) * Math.PI) / 180) * distUp +
          8,
        dist: distUp,
      });
      pointsUp2.push({
        angle: angle + angleExtra,
        x:
          centerX +
          radius * Math.cos(((-angle + angleExtra) * Math.PI) / 180) * distUp +
          8,
        y:
          centerY +
          radius * Math.sin(((-angle + angleExtra) * Math.PI) / 180) * distUp +
          8,
        dist: distUp,
      });
    }

    // -------------
    // Canvas stuff
    // -------------
    function drawLine(points) {
      let origin = points[0];

      ctx.beginPath();
      ctx.strokeStyle = '#610C04';
      ctx.lineJoin = 'round';
      ctx.moveTo(origin.x, origin.y);
      ctx.lineWidth = 2;

      for (let i = 0; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
      }

      ctx.lineTo(origin.x, origin.y);
      ctx.stroke();
    }

    // function connectPoints(pointsA, pointsB) {
    //   for (let i = 0; i < pointsA.length; i++) {
    //     ctx.beginPath();
    //     ctx.strokeStyle = 'rgba(255,255,255,0.5)';
    //     ctx.moveTo(pointsA[i].x, pointsA[i].y);
    //     ctx.lineTo(pointsB[i].x, pointsB[i].y);
    //     ctx.stroke();
    //   }
    // }

    function update(dt) {
      let audioIndex, audioValue;

      // get the current audio data
      analyserL.getByteFrequencyData(audioDataArrayL);
      analyserR.getByteFrequencyData(audioDataArrayR);

      for (let i = 0; i < pointsUp.length; i++) {
        audioIndex =
          Math.ceil(pointsUp[i].angle * (bufferLengthL / (pCircle * 2))) | 0;
        // get the audio data and make it go from 0 to 1
        audioValue = audioDataArrayL[audioIndex] / 255;

        pointsUp[i].dist = 0.8 + audioValue * 0.8;
        pointsUp[i].x =
          centerX +
          radius *
            Math.cos((-pointsUp[i].angle * Math.PI) / 180) *
            pointsUp[i].dist;
        pointsUp[i].y =
          centerY +
          radius *
            Math.sin((-pointsUp[i].angle * Math.PI) / 180) *
            pointsUp[i].dist;

        audioIndex =
          Math.ceil(pointsUp1[i].angle * (bufferLengthR / (pCircle * 2))) | 0;
        // get the audio data and make it go from 0 to 1
        audioValue = audioDataArrayR[audioIndex] / 255;
        pointsUp1[i].dist = 0.74 + audioValue * 0.8;
        pointsUp1[i].x =
          centerX +
          radius *
            Math.cos((-pointsUp1[i].angle * Math.PI) / 180) *
            pointsUp1[i].dist;
        pointsUp1[i].y =
          centerY +
          radius *
            Math.sin((-pointsUp1[i].angle * Math.PI) / 180) *
            pointsUp1[i].dist;

        ctx.strokeStyle = '#400C04';
        audioIndex =
          Math.ceil(pointsUp2[i].angle * (bufferLengthL / (pCircle * 2))) | 0;
        // get the audio data and make it go from 0 to 1
        audioValue = audioDataArrayR[audioIndex] / 255;
        pointsUp2[i].dist = 0.76 + audioValue * 0.8;
        pointsUp2[i].x =
          centerX +
          radius *
            Math.cos((-pointsUp2[i].angle * Math.PI) / 180) *
            pointsUp2[i].dist;
        pointsUp2[i].y =
          centerY +
          radius *
            Math.sin((-pointsUp2[i].angle * Math.PI) / 180) *
            pointsUp2[i].dist;

        // audioIndex =
        //   Math.ceil(pointsDown[i].angle * (bufferLengthR / (pCircle * 2))) | 0;
        // // get the audio data and make it go from 0 to 1
        // audioValue = audioDataArrayR[audioIndex] / 255;

        // pointsDown[i].dist = 0.9 + audioValue * 0.2;
        // pointsDown[i].x =
        //   centerX +
        //   radius *
        //     Math.cos((-pointsDown[i].angle * Math.PI) / 180) *
        //     pointsDown[i].dist;
        // pointsDown[i].y =
        //   centerY +
        //   radius *
        //     Math.sin((-pointsDown[i].angle * Math.PI) / 180) *
        //     pointsDown[i].dist;
      }
    }

    function draw(dt) {
      requestAnimationFrame(draw);

      if (canPlay) {
        update(dt);
      }

      ctx.clearRect(0, 0, Canvas?.current?.width, Canvas?.current?.height);
      drawLine(pointsUp);
      // drawLine(pointsUp1);
      // drawLine(pointsUp2);
      // cacheDom.logo.style.transform = `scale(${})`;

      if (centerSectionWrapper.current)
        centerSectionWrapper.current.style.transform = `scale(${pointsUp[0].dist})`;
      if (centerSectionSpeaker.current)
        centerSectionSpeaker.current.style.transform = `scale(${
          pointsUp1[pointsUp1.length - 1].dist
        })`;
    }
    draw();
  };
  useEffect(() => {
    const socket = io(`${config.api.API_URL}`, { transports: ['websocket'] });
    const handleScroll = (e) => {
      const header = document.querySelector('.app-header');
      if (window.scrollY > 1) {
        header.classList.add('fixed-header');
      } else if (window.scrollY <= 1) {
        header.classList.remove('fixed-header');
      }
    };
    document.querySelector('.startup-loader').style.display = 'none';
    document.addEventListener('scroll', handleScroll, { passive: true });
    if (
      restParams.lang &&
      (restParams.lang === 'en' ||
        restParams.lang === 'hy' ||
        restParams.lang === 'ru')
    ) {
      setLang(restParams.lang);
    } else if (lang) {
      setLang(lang);
    } else {
      setLang('en');
    }
    setTheme('night');
    //! init default socket transfers
    socket.on('connect', () => {
      const result = { _id: socket.id };

      function setPosition(position) {
        const { latitude, longitude } = position.coords;
        result.location = `Your current location is: ${latitude}, ${longitude}`;
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(setPosition);
      } else {
        result.location = 'Geolocation is not supported by this browser.';
      }

      socket.emit('client-connected', result);
      socket.on('connected-clients', (connectedClients) => {
        setListening(connectedClients.length);
      });
      socket.on('all-likes', (Likes) => {
        setLikes(Likes.length);
      });
    });

    AudioElement = new Audio();
    AudioElement.muted = true;
    AudioElement.setAttribute('muted', ''); // leave no stones unturned :)
    // AudioElement.addEventListener('ended', async () => {
    //   try {
    //     console.log('ended');
    //     console.log(streamID);
    //     LoadAudio();
    //   } catch (error) {
    //     console.log('error in next track ');
    //     AudioElement.currentTime = 0;
    //     AudioElement.volume = audioVolume;
    //     AudioElement.play();
    //   }
    // });
    (async () => {
      await checkToken();
    })();
    return () => {
      document.removeEventListener('scroll', handleScroll);
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //! play if play state is true
    if (canPlay && play && AudioElement) {
      StreamInterval = setInterval(() => {
        let currentTime = AudioElement.currentTime | 0;
        let duration = AudioElement.duration | 0;
        let minutesDur = '0' + Math.floor(duration / 60);
        let secondsDur = '0' + (duration - minutesDur * 60);
        let dur = minutesDur.substr(-2) + ':' + secondsDur.substr(-2);
        let minutesCur = '0' + Math.floor(currentTime / 60);
        let secondsCur = '0' + (currentTime - minutesCur * 60);
        let cur = minutesCur.substr(-2) + ':' + secondsCur.substr(-2);
        setNumberCurrent(cur);
        setNumberDuration(dur);
        if (currentTime >= duration) {
          LoadAudio(streamID);
        }
      }, 1000);

      LoadAudio(streamID);
      setOneTimestate(false);
      AudioElement.play();
      AudioElement.muted = false;
      audioCtx = new (window.AudioContext || window.webkitAudioContext)();

      //! round circle
      splitter = audioCtx.createChannelSplitter();
      analyserL = audioCtx.createAnalyser();
      analyserL.fftSize = 8192;
      analyserR = audioCtx.createAnalyser();
      analyserR.fftSize = 8192;
      splitter.connect(analyserL, 0, 0);
      splitter.connect(analyserR, 1, 0);
      // Make a buffer to receive the audio data
      bufferLengthL = analyserL.frequencyBinCount;
      audioDataArrayL = new Uint8Array(bufferLengthL);
      bufferLengthR = analyserR.frequencyBinCount;
      audioDataArrayR = new Uint8Array(bufferLengthR);
      // handle can play
      source = audioCtx.createMediaElementSource(AudioElement);
      source.connect(splitter);
      splitter.connect(audioCtx.destination);

      if (Canvas.current) {
        ctx = Canvas.current.getContext('2d');
        animate();
      } else {
        console.log('canvas not created in some reason');
      }
    }
    // eslint-disable-next-line
  }, [play]);

  useEffect(() => {
    clearInterval(StreamInterval);
    if (play && AudioElement) {
      StreamInterval = setInterval(() => {
        let currentTime = AudioElement.currentTime | 0;
        let duration = AudioElement.duration | 0;
        let minutesDur = '0' + Math.floor(duration / 60);
        let secondsDur = '0' + (duration - minutesDur * 60);
        let dur = minutesDur.substr(-2) + ':' + secondsDur.substr(-2);
        let minutesCur = '0' + Math.floor(currentTime / 60);
        let secondsCur = '0' + (currentTime - minutesCur * 60);
        let cur = minutesCur.substr(-2) + ':' + secondsCur.substr(-2);
        setNumberCurrent(cur);
        setNumberDuration(dur);
        if (currentTime >= duration) {
          LoadAudio(streamID);
        }
      }, 1000);
    }
    LoadAudio(streamID);
    return () => {
      clearInterval(StreamInterval);
    };
    // eslint-disable-next-line
  }, [streamID]);

  //set song audio volume as audioVolume state changes
  useEffect(() => {
    if (AudioElement) {
      AudioElement.volume = audioVolume;
    } else {
      console.log('audio file is not defined yet');
    }
  }, [audioVolume]);

  // useEffect(() => {
  //   console.log('run only once');
  //   Canvas.current = requestAnimationFrame(animate);
  // }, []);

  if (loggedIn === true || keyFile === true) {
    return (
      <GlobalContext.Provider
        value={{
          login,
          logout,
          loggedIn,
          user,
          lang,
          setLang,
          theme,
          setTheme,
          adult,
          setAdult,
          PlayStream,
          Mute,
          Like,
          LoadAudio,
          GetAudio,
          centerSectionWrapper,
          centerSectionSpeaker,
          likeFade,
          play,
          numberCurrent,
          numberDuration,
          likes,
          listening,
          trackName,
          audioVolume,
          introText,
          canPlay,
          AudioElement,
          Canvas,
          ctx,
          analyser,
          streamID,
          setStreamID,
          streamName,
          setStreamName,
          streams,
          setStreams,
        }}>
        <ModalProvider.Provider
          value={{
            open,
            setOpen,
            modalToggle,
            modalFor,
            setModalFor,
            openModal,
            closeModal,
          }}>
          <LazyLoadComponent component={<ModalWrapper />} />
          <Router history={history}>
            <Switch>
              <Suspense fallback={<Loading />}>
                <Header />
                <div className='app-content'>
                  <RouterComponent />
                </div>
                <LazyLoadComponent component={<Footer />} />
              </Suspense>
            </Switch>
          </Router>
        </ModalProvider.Provider>
      </GlobalContext.Provider>
    );
  } else {
    return <Loading />;
  }
}

export default App;
