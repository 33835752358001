import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from '../../helpers/globalContext';

export const AccountIcon = React.memo(() => {
  const { user } = useGlobal();
  let icoName = '';

  if (user.firstName && user.firstName.hy) {
    icoName = user.firstName.hy;
  } else if (
    !(
      user && // 👈 null and undefined check
      Object.keys(user).length === 0 &&
      Object.getPrototypeOf(user) === Object.prototype
    )
  ) {
    icoName = 'իմ Էջը';
  } else {
    icoName = 'Մուտք';
  }

  return (
    <Link className='account-icon' to={'/account/'}>
      <img src='/assets/Account_icon.svg' alt='account' />
      <p className='account-icon-text'>{icoName}</p>
      {user && user._id && <span className='account-icon-indicator'></span>}
    </Link>
  );
});
