import axios from 'axios';

import config from '../../config';

import { cookies } from './cookies';
// const LocalData =
//   localStorage.getItem("data") &&
//   localStorage.getItem("data") !== "null" &&
//   localStorage.getItem("data") !== "undefined" &&
//   localStorage.getItem("data") !== ""
//     ? JSON.parse(localStorage.getItem("data"))
//     : "";
const API = axios.create({ baseURL: `${config.api.API_URL}/${config.api.API_VERSION}/` });

export const settings = {
  onUploadProgress(progressEvent) {
    // eslint-disable-next-line
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
  },
  headers: {
    'client-keyFile': config.authentication.key,
    'client-token': cookies.get(config.authentication.cookieToken)
  }
};

export function get(
  route,
  query,
  sort,
  uploadType,
  tags,
  sale,
  NoNotAvailable
) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  const completeRoute = `${route}${query ? `?q=${JSON.stringify(query)}` : ''}${
    sort ? `&sort=${JSON.stringify(sort)}` : ''
  }${uploadType ? `&uploadType=${JSON.stringify(uploadType)}` : ''}${
    tags ? `&tags=${JSON.stringify(tags)}` : ''
  }${sale ? `&sale=${JSON.stringify(sale)}` : ''}${
    NoNotAvailable ? `&NoNotAvailable=${JSON.stringify(NoNotAvailable)}` : ''
  }`;
  return new Promise((resolve, reject) => {
    if (route && completeRoute && completeRoute.length !== 0) {
      // console.log(LocalData[route]);
      // if (
      //   (LocalData[route] && query && sort) ||
      //   LocalData[route]
      //   // Object.keys(query).length !== 0 &&
      //   // query.constructor === Object
      // ) {
      //   console.log(
      //     "%c Get information from LocalStorage!",
      //     "background: #222; color: #bada55"
      //   );
      //   if (query) {
      //     let result = LocalData[route].find((element, index, array) => {
      //       // while (start <= Math.sqrt(element)) {
      //       //   if (element % start++ < 1) {
      //       //     return false;
      //       //   }
      //       // }
      //       if (element[Object.keys(query)]) return element;
      //     });
      //     resolve(result);
      //   } else {
      //     resolve(LocalData[route]);
      //   }
      // } else {
      //   console.log(
      //     "%c Get information from Server!",
      //     "background: #222; color: #f2c900"
      //   );
      API.get(completeRoute, settings)
        .then(({ data }) =>
          resolve(data))
        .catch((error) =>
          reject(error));
      // }
    }
  });
}
export function getById(route, id) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return new Promise((resolve, reject) => {
    API.get(`${route}/${id}`, settings)
      .then(({ data }) =>
        resolve(data))
      .catch((error) =>
        reject(error));
  });
}
export function post(route, data) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return new Promise((resolve, reject) => {
    API.post(route, data, settings)
      .then(({ data }) =>
        resolve(data))
      .catch((error) =>
        reject(error));
  });
}
export function update(route, id, data) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return new Promise((resolve, reject) => {
    API.put(`${route}/${id}`, data, settings)
      .then(({ data }) =>
        resolve(data))
      .catch((error) =>
        reject(error));
  });
}
export function remove(route, id) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return new Promise((resolve, reject) => {
    API.delete(`${route}/${id}`, settings)
      .then(({ data }) =>
        resolve(data))
      .catch((error) =>
        reject(error));
  });
}
export const upload = (id, files, lang, destiny) => {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return Promise.all(
    files
      && files.map((file, index) => {
        const formData = new FormData();
        id && formData.append('obj_id', id);
        file && formData.append('file', file);
        lang && formData.append('lang', lang);
        destiny && formData.append('destiny', destiny);
        return new Promise((resolve, reject) => {
          API.post(config.api.uploads, formData, settings)
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
  );
};

export default API;
