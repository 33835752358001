import React from 'react';
import { Link } from 'react-router-dom';

export function Logo({ mode }) {
  return (
    <Link to={'/'} className='logo' aria-label='Go to home page'>
      <img
        src={`/assets/logo.svg`}
        alt='logotip'
        width={90}
        height={50}
      />
    </Link>
  );
}
