import React, { useEffect, useState } from 'react';
import Flip from 'react-reveal/Flip';
import { useGlobal } from '../helpers/globalContext';

export const LanguageBar = React.memo(({ className }) => {
  let [open] = useState(false);
  const { lang, setLang } = useGlobal();
  let [showDropDown, setShowDropDown] = useState(false);

  const dropDowntoggle = () => {
    setShowDropDown(!showDropDown);
  };
  const changeLanguage = (e) => {
    setLang(e.currentTarget.lang);
    document.documentElement.lang = lang;
  };

  useEffect(() => {}, [open]);

  return (
    <div className={`lang-bar ${className}`}>
      <div className='dropdown'>
        <button
          className='btn btn-white dropdown-toggle'
          type='button'
          onClick={dropDowntoggle}
        >
          {lang === 'en' ? (
            <img
              src='/assets/united-states.webp'
              alt='english'
              width={30}
              height={30}
            />
          ) : lang === 'ru' ? (
            <img src='/assets/russia.webp' alt='rus' width={30} height={30} />
          ) : lang === 'hy' ? (
            <img
              src='/assets/armenia.webp'
              alt='armenian'
              width={30}
              height={30}
            />
          ) : (
            ''
          )}
        </button>
        <Flip left duration={500}>
          <ul className={`dropdown-menu ${showDropDown ? 'show' : ''}`}>
            {lang !== 'en' ? (
              <li onClick={dropDowntoggle}>
                <button
                  className='dropdown-item'
                  onClick={changeLanguage}
                  lang='en'
                >
                  <img
                    src='/assets/united-states.webp'
                    alt='english'
                    width={30}
                    height={30}
                  />
                </button>
              </li>
            ) : (
              ''
            )}
            {lang !== 'ru' ? (
              <li onClick={dropDowntoggle}>
                <button
                  className='dropdown-item'
                  onClick={changeLanguage}
                  lang='ru'
                >
                  <img
                    src='/assets/russia.webp'
                    alt='rus'
                    width={30}
                    height={30}
                  />
                </button>
              </li>
            ) : (
              ''
            )}
            {lang !== 'hy' ? (
              <li onClick={dropDowntoggle}>
                <button
                  className='dropdown-item'
                  onClick={changeLanguage}
                  lang='hy'
                >
                  <img
                    src='/assets/armenia.webp'
                    alt='armenian'
                    width={30}
                    height={30}
                  />
                </button>
              </li>
            ) : (
              ''
            )}
          </ul>
        </Flip>
      </div>
    </div>
  );
});
