import React, { useState, useEffect, lazy } from 'react';

// import Navigation from '../navigation/navigation';
import { Logo } from '../logo';
import HeaderElements from './header_elements';
import { useHistory, useLocation } from 'react-router-dom';
import config from '../../../config';
import * as API from '../../helpers/api';
import { SumTotal } from '../../helpers/sum';
import { useGlobal } from '../../helpers/globalContext';
import { LazyLoadComponent } from '../../helpers/lazyLoadComponents';

const Navigation = lazy(() => import('../navigation/navigation'));

export default function Header() {
  const history = useHistory();
  const [logoType, setLogoType] = useState('');
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openDesktopMenu, setOpenDesktopMenu] = useState(false);

  const { cart, setCartData, user, lang, streams, setStreams, setStreamName } =
    useGlobal();
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [isBusy, setBusy] = useState(true);
  const [loadNavigation, setLoadNavigation] = useState(true);
  const location = useLocation();

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };
  history.listen((res) => {
    let Market = history?.location.pathname?.split('/')[2];
    if (Market === 'kitchen') {
      setLogoType('red');
    } else if (Market === 'store') {
      setLogoType('blue');
    }
  });

  useEffect(() => {
    let Market = history?.location.pathname?.split('/')[2];
    if (Market === 'kitchen') {
      setLogoType('red');
    } else if (Market === 'store') {
      setLogoType('blue');
    }
    // eslint-disable-next-line
  }, [logoType]);

  useEffect(() => {
    (async () => {
      let streams = await API.get(config.api.stream, {}, { index: 1 }, true);
      setStreams(streams);
      // setLoadNavigation(true);
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <header className='app-header'>
      <div className='app-header-background'></div>
      <Logo mode={logoType} />
      {streams && (
        <LazyLoadComponent
          component={
            <Navigation
              openMobileMenu={openMobileMenu}
              setOpenMobileMenu={setOpenMobileMenu}
              openDesktopMenu={openDesktopMenu}
              setOpenDesktopMenu={setOpenDesktopMenu}
              activeRoute={activeRoute}
            />
          }
          state={true}
        />
      )}
      {/* <HeaderElements
        setOpenMobileMenu={setOpenMobileMenu}
        cart={{
          cart,
          setCartData,
          total,
          setTotal,
          quantity,
          setQuantity,
          user,
        }}
      /> */}
    </header>
  );
}
